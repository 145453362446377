:root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 400px;
    --wheel-slice-spacing: 30px;
    --wheel-color: #da3768;
    --neutral-color: white;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;
    --slice-background: white;
    --slice-font-color: white;
    /* --slice-font-size: 2px; */
    --wheel-border-color: red;
    --spin-btn-color: white;
    --padding-ring: white;
    --outer-ring: #1b6568;
    --circle-ring-size: 30px;
    --inner-ring: #1b6568;
    --inner-ring-size: 8px;
    --ring-color:rgb(108, 108, 31);
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
    content: "";
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 0px;
    border-left-width: 0;
    /* color: red; */
}

.wheel-container::before {
    right: 0px;
    border-right-color: var(--wheel-border-color);
}

.wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
}

/* spin btn */
.spin{
    position: absolute;
    width: 150px;
    height: 67px;
    top: 61%;
    right: 38%;
    transform: translateY(-50%) rotate(90deg);
    z-index: 10;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    color: black;
}

/* Roue */
.wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    /* border: solid var(--wheel-color) var(--wheel-border-size); */
    border: solid var(--wheel-color) var(--inner-ring-size);
    background-color: var(--wheel-color);
    transform: rotate(calc(1deg * 90 / var(--nb-item) * 2));
    /* cursor: pointer; */
}


/* Centre de la roue = rond blanc au centre */
.wheel::after {
    display: block;
    position: absolute;
    content: "";
    background-color: var(--spin-btn-color);
    width: 80px;
    height: 80px;
    text-align: center;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

/* Element sur la roue */
.wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;

    /* position de l'item */
    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%)
        rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    /* texte */
    color: var(--slice-font-color);
    text-align: center;
    padding: 0 25px 0 50px;
    font-size: calc(1px * var(--slice-font-size));
}

.title{
    display: block;
    width: 150px;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }

/* Background de l'élément = triangle rose plus clair */
.wheel-item::before {
    content: " ";
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    --slice-max-width: calc(
        var(--PI) * var(--wheel-size) + var(--wheel-size) / 2
    );
    --slice-width: calc(
        (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
    );
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
    border-right: solid var(--slice-background) calc(var(--wheel-size) / 2);
}

@import './CircleRing.css';

@media (max-width: 768px) {
    :root{
        --wheel-size: 350px;
        --inner-ring-size: 6px;
    }
    .main{
        margin-top: 50px;
    }
    .title{
      font-size: 13px;
    }
    /* Centre de la roue = rond blanc au centre */
    .wheel::after {
        width: 50px;
        height: 50px;
    }

    .spin{
          position: absolute;
          width: 152px;
          height: 64px;
          top: 64%;
          right: 37%;
          font-size: 15px;
    }
}
@media (max-width: 390px) {
:root{
    --wheel-size: 320px;
    --inner-ring-size: 3px;
}
.spin{
      position: absolute;
      width: 140px;
      height: 60px;
      top: 64%;
      right: 37%;
      font-size: 15px;
}
}
@media (max-width: 375px) {
:root{
    --wheel-size: 290px;
    --inner-ring-size: 3px;
}
.circle-arrow{
    width: 140px;
    height: 51px;
}
.title{
  width: 100px;
  height: 20px;
}
.spin{
      position: absolute;
      width: 137px;
      height: 66px;
      top: 64%;
      right: 37%;
      font-size: 15px;
}
.title{
  font-size: 13px;
}
}
@media (max-width: 360px) {
:root{
    --wheel-size: 270px;
}
.wheel::after {
    width: 40px;
    height: 40px;
}

.spin{
      position: absolute;
      width: 145px;
      height: 68px;
      top: 67%;
      right: 36%;
      font-size: 13px;
}
}
@media (max-width: 320px) {
:root{
    --wheel-size: 230px;
    --circle-ring-size: 25px;
}
.circle-arrow{
    width: 130px;
    height: 42px;
    right: -44px;
}
.title{
    width:60px;
    font-size: 10px;
}
.main{
    margin-top: 20px;
}
.wheel::after {
    width: 40px;
    height: 40px;
}

.spin{
      position: absolute;
      width: 140px;
      height: 66px;
      top: 69%;
      right: 34%;
      font-size: 13px;
}
}